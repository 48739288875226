<template>
  <KTPortlet title="Tạo mới tiệm" headSize="md">
    <template v-slot:body>
      <b-form @submit.prevent="onSubmit">
        <div class="row">
          <b-form-group label="Tên " class="col-7">
            <b-form-input
              id="input-1"
              type="text"
              required
              v-model="form.name"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Tên viết tắt" class="col-5">
            <b-form-input type="text" v-model="form.shortName"></b-form-input>
          </b-form-group>
        </div>
        <b-form-group id="input-group-2" label="Địa chỉ" label-for="input-2">
          <b-form-input
            id="input-2"
            type="text"
            required
            v-model="form.address"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Telephone" label-for="input-3">
          <b-form-input
            id="input-3"
            type="text"
            v-model="form.telePhone"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Mobile phone"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            type="text"
            v-model="form.mobilePhone"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Email" label-for="input-3">
          <b-form-input
            id="input-3"
            type="email"
            v-model="form.email"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-4" label="Manager" label-for="input-4">
          <b-form-select
            id="input-4"
            v-model="form.managerId"
            :options="listManager"
            value-field="_id"
            text-field="name"
          >
            <template v-slot:first v-if="listManager.length === 0">
              <b-form-select-option disabled
                >Không tìm thấy Manager nào</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group id="input-group-3" label="Website" label-for="input-3">
          <b-form-input
            id="input-3"
            type="text"
            required
            v-model="form.website"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Hoàn thành</b-button>
      </b-form>
    </template>
  </KTPortlet>
</template>

<style lang="scss"></style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { Status } from "./../../constans";
import { getAllUsersManager } from "@/api/outlet";
import { createSuccess } from "@/helper/index";

export default {
  name: "Create",
  components: {
    KTPortlet
  },
  data() {
    return {
      states: [
        {
          value: true,
          text: "Kích hoạt"
        },
        {
          value: false,
          text: "Chưa kích hoạt"
        }
      ],
      form: {
        name: "",
        shortName: "",
        address: "",
        website: "",
        telePhone: "",
        mobilePhone: "",
        email: "",
        managerId: ""
      },
      listManager: []
    };
  },
  mounted() {
    this.getListUser();
  },

  methods: {
    onSubmit() {
      if (this.form.managerId === "") delete this.form.managerId;
      this.$store.dispatch("createOutlet", this.form).then(() => {
        createSuccess("outlet");
      });
    },

    getListUser() {
      getAllUsersManager().then(res => {
        this.listManager = res.data.filter(
          user => user.status === Status.Actived
        );
      });
    }
  }
};
</script>
